.scorecard__segment-table__issue--neutral, .scorecard__segment-table__issue--minor, .scorecard__segment-table__issue--major, .scorecard__segment-table__issue--critical {
  border: 1px solid #998e13;
  background-color: #fbffbf;
  border-radius: 9px;
  padding: 3px;
  display: inline-block;
  font-size: 13px;
  margin: 5px;
  cursor: pointer;
}

.scorecard__segment-table__issue--neutral:focus, .scorecard__segment-table__issue--minor:focus, .scorecard__segment-table__issue--major:focus, .scorecard__segment-table__issue--critical:focus {
  opacity: 0.5;
}

.scorecard__segment-table__issue--major {
  border: 1px solid #996413;
  background-color: #ffe7ba;
}

.scorecard__segment-table__issue--critical {
  border: 1px solid #991916;
  background-color: #ffdbd6;
}

.scorecard__segment-table__issue--neutral {
  border: 1px solid black;
  background-color: white;
}

.scorecard__segment-table__close-button{
  top: -3px;
  color: red;
  font-size: 10px;
  text-decoration: none;
  position: relative;
  padding-left: 3px;
}

.scorecard__segment-table__category {
  color: #007BFF;
}