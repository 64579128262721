.view-projects__row:nth-child(even) {
    background: #eee;
}

.view-projects__table-cell {
    text-align: left;
    padding: 5px;
}

.view-projects__button {
    background: green;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
}

.view-projects__download-button {
  margin: 5px 0 15px 0;
  height: 20px; 
  width: 200px; 
  font-family: Roboto, sans-serif;
  cursor: pointer;
  background-color: rgb(239, 239, 239);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: black;
  border-radius: 2px;
  text-decoration: none;
  font-size: 16px;
  border: 1px solid rgb(118, 118, 118);
  text-align: center;
}

.view-projects__download-button:hover {
  background-color: rgb(226, 225, 225);
}

.view-projects__button--red {
    background: red;
}

.view-projects__heading {
    font-size: 30px;
    margin: 40px 0 20px 0;
}

.view-projects__table-container {
    width: 100%;
    overflow-x: scroll;
}

.view-projects__table {
    width: 100%;
    border: 1px solid #eeeeee;
    overflow-y: scroll;
    min-width: 800px;
}

.view-projects__table-cell-header {
    text-align: left;
}

.view-projects__error {
    color: red;
    margin-bottom: 10px;
    display: inline-block;
}