.edit-project__heading {
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.edit-project__table-container {
  width: 100%;
  overflow-x: scroll;
}

.edit-project__table {
  width: 100%;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  min-width: 800px;
}

.edit-project__row:nth-child(odd) {
  background: #eee;
}

.edit-project__table-cell {
  text-align: left;
  padding: 5px;
}

.edit-project__error {
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}

.edit-project__delete-button {
  background: red;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
}

.edit-project__delete-button:hover {
  opacity: .8;
}

.edit-project__table--second {
  margin-top: 50px;
}

.edit-project__success{
  color: #1b663e;
  margin-bottom: 10px;
  display: inline-block;
  -webkit-animation: flash 5.5s forwards;
          animation: flash 5.5s forwards;
}

.edit-project__warning {
margin-bottom: 10px;
font-weight: bold;
}

@-webkit-keyframes flash {
0% {
    opacity: 1
}

75% {
    opacity: 1
}

100% {
    opacity: 0
}
}

@keyframes flash {
0% {
    opacity: 1
}

75% {
    opacity: 1
}

100% {
    opacity: 0
}
}