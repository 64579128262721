.reports {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow-x: scroll;
}

.reports__buttons {
  padding-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.reports__button {
  margin: 5px 0;
  height: 45px; 
  width: 300px; 
  font-family: Roboto, sans-serif;
  cursor: pointer;
  background-color: rgb(239, 239, 239);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: black;
  border-radius: 2px;
  text-decoration: none;
  font-size: 16px;
  border: 1px solid rgb(118, 118, 118);
}

.reports__button:hover {
  background-color: rgb(226, 225, 225);
}

.reports__table {
	border-collapse: collapse;
  display: inline-block;
  min-width: 800px;
}

.reports__cell, .reports__header-cell {
	border: 2px solid black;
	padding: 8px;
}

.reports__header-cell {
	background-color: #c5d1eb;
}

.reports__cell {
  text-align: right;
}

.reports__header-cell--neutral, .reports__cell--neutral {
  background-color: rgb(211,211,211, 0.5);
}

.reports__header-cell--minor, .reports__cell--minor {
  background-color: #feffdb;
}

.reports__header-cell--major, .reports__cell--major {
  background-color: #ffe5c9;
}

.reports__header-cell--critical, .reports__cell--critical {
  background-color: #ffeae5;
}

.reports__header-cell--white, .reports__cell--white{
  background-color: white;
}

.reports__header-cell--total {
  background-color: #d6e3ff;
}

.reports__cell--subheading {
	background-color: #65699e;
	color: #dfd998;
	text-align: center;
	font-weight: bold;
  border: 2px solid black;
  padding: 5px;
}

.reports__row--subtotal {
  background: #f4ed96;
}

.reports__row--total {
  background: #d6e3ff;
}

.reports__error {
  color: red;
  font-weight: bold;
}