.advanced-settings-modal {
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  font-size: 18px;
}

.advanced-settings-modal__content-wrapper {
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  border-radius: 4px;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
}

.advanced-settings-modal__content {
  padding-bottom: 30px;
}

.advanced-settings-modal__buttons{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.advanced-settings-modal__button {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

.advanced-settings-modal__button--clear {
  background: #dc3545;
  border-color: #dc3545;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.advanced-settings-modal__button--close {
  background: #28a745;
  border-color: #28a745;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.advanced-settings-modal__button--close:disabled {
  opacity: 0.6;
}

.advanced-settings-modal__heading {
  font-size: 20px;
  margin-bottom: 20px;
}

.advanced-settings-modal__close-icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
}

.advanced-settings-modal__close-icon {
  cursor: pointer;
}

.advanced-settings-modal__expandable-section {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.advanced-settings-modal__expandable-section:hover {
  opacity: .6;
}