.add-issue-modal {
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  font-size: 18px;
}

.add-issue-modal__content-wrapper {
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  border-radius: 4px;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 500px;
}

.add-issue-modal__content {
  padding-bottom: 30px;
}

.add-issue-modal__buttons{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.add-issue-modal__button {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

.add-issue-modal__button--cancel {
  background: #dc3545;
  border-color: #dc3545;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.add-issue-modal__button--continue {
  background: #28a745;
  border-color: #28a745;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
}

.add-issue-modal__button--continue:disabled {
  opacity: 0.6;
}

.add-issue-modal__heading {
  font-size: 20px;
  margin-bottom: 20px;
}

.add-issue-modal__close-icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
}

.add-issue-modal__close-icon {
  cursor: pointer;
}

.add-issue-modal__buttons--second-page {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.add-issue-modal__note {
  width: 100%;
  min-height: 100px;
  margin-bottom: 40px;
  resize: none;
}

.add-issue-modal__expandable-section {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.add-issue-modal__expandable-section:hover {
  opacity: .6;
}

.add-issue-modal__tooltip {
  padding: 20px;
}

.add-issue-modal__tooltip-heading, .add-issue-modal__tooltip-subheader {
  font-size: 24px;
  font-weight: 700;
  width: 50%;
  text-align: left;
}

.add-issue-modal__tooltip-subheader {
  font-size: 18px;
}

.add-issue-modal__critical-note {
  color: red;
  padding: 5px;
  font-size: 14px;
}

.add-issue-modal__copyable-text {
  text-align: left;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  display: inline-block;
}

.add-issue-modal__copy-paste-icon {
  margin-left: 5px;
}

.add-issue-modal__copyable-text-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}