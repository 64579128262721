.import-typology__heading {
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.import-typology__table-container {
  width: 100%;
  overflow-x: scroll;
}

.import-typology__table {
  width: 100%;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  min-width: 800px;
}

.import-typology__row:nth-child(odd) {
  background: #eee;
}

.import-typology__table-cell {
  text-align: left;
  padding: 5px;
}

.import-typology__error {
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}

.import-typology__success{
  color: #1b663e;
  margin-bottom: 10px;
  display: inline-block;
}