.manage-users__table-container {
  width: 100%;
  overflow-x: scroll;
}

.manage-users__heading {
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.manage-users__table {
  width: 100%;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  min-width: 800px;
}

.manage-users__table-cell, .manage-users__table-cell-header  {
  text-align: left;
  padding: 5px;
}

.manage-users__table-cell-header {
  text-align: left;
}

.manage-users__row:nth-child(even) {
  background: #eee;
}

.manage-user__button {
  background: green;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
}

.manage-user__button--red {
  background: red;
}

.manage-user__button:hover {
  opacity: 0.8;
}

.manage-users__error{
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}

.manage-users__success{
  color: #1b663e;
  margin-bottom: 10px;
  display: inline-block;
  -webkit-animation: flash 5.5s forwards;
          animation: flash 5.5s forwards;
}

@-webkit-keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

@keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

