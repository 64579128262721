.accordion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 8px;
  background: rgb(246, 246, 246);
  border: 1px solid  #c5c5c5;
  border-radius: 3px;
  margin-top: 2px;
}

.accordion:hover {
  background: rgb(237, 237, 237);
  cursor: pointer;
}

.accordion__header {
  padding-left: 6px;
  font-size: 14px;
}

.accordion__triangle {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.accordion__triange--rotated {
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}

.accordion--open, .accordion--open:hover {
  background: rgb(0, 127, 255);
  color: white;
  fill: white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion__content {
  padding: 16px 32px;
  border: 1px solid rgb(221, 221, 221);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: rgb(51, 51, 51);
  font-size: 14px;
  max-height: 500px;
  overflow-y: scroll;
}

.accordion__image-container {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.accordion__image1, .accordion__image4 {
  max-width: 450px;
  width: 100%;
}

.accordion__image2 {
  max-width: 500px;
  width: 100%;
}

.accordion__image3 {
  max-width: 850px;
  width: 100%;
}

.accordion__image5, .accordion__image6, .accordion__image7 {
  max-width: 650px;
  width: 100%;
}