body {
    margin: 0;
    font-family: Helvetica, Arial, sansserif;
}

.app {
    background: white;
    display: flex;
    min-height: 100vh;
    box-sizing: border-box;
    border: 18px solid #BDCDFF;
}

.app__content {
    padding: 14px 8px 8px 8px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.link {
    font-size: 15px;
}

pre {
    white-space: break-spaces !important;
}