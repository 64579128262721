.editor__header {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.editor__heading {
  display: inline;
  margin: 0;
}

.editor__container {
  margin-top: 5px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  padding: 3px;
}

.editor__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0px;
  padding-left: 0px;
  background: #e9e9e9;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.editor_content{
  padding: 15px;
}

.editor__tab {
  margin: 3px 3px 0 3px;
  padding: 10px;
  color: #454545;
  border: 1px solid #c5c5c5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #f6f6f6;
  border-bottom: none;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial,Helvetica,sans-serif;
}

.editor__tab:hover {
  border: 1px solid #CCC;
  background: #ededed;
  border-bottom: none;
}

.editor__tab--selected, .editor__tab--selected:hover {
  background: #007fff;
  border: 1px solid #003eff;
  color: white;
}

.editor__grade {
  margin-right: 5px;
}

.editor__button {
  margin-left: 5px;
}

.editor__success{
  color: #1b663e;
  display: inline-block;
  -webkit-animation: flash 5.5s forwards;
          animation: flash 5.5s forwards;
}

.editor__error {
  color: red;
  display: inline-block;
}

.editor__score {
  font-size: 15px;
}

@-webkit-keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

@keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}
