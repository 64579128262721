.create-project__heading {
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.create-project__table-container {
  width: 100%;
  overflow-x: scroll;
}

.create-project__table {
  width: 100%;
  border: 1px solid #eeeeee;
  overflow-y: scroll;
  min-width: 800px;
}

.create-project__row:nth-child(odd) {
  background: #eee;
}

.create-project__table-cell {
  text-align: left;
  padding: 5px;
}

.create-project__error {
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}

.create-project__success{
  color: #1b663e;
  margin-bottom: 10px;
  display: inline-block;
  -webkit-animation: flash 5.5s forwards;
          animation: flash 5.5s forwards;
}


@-webkit-keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}


@keyframes flash {
  0% {
      opacity: 1
  }

  75% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}