.edit-profile__heading{
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.edit-profile__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.edit-profile__form-label{
  font-size: 14px;
}

.edit-profile__form-input {
margin-left: 5px;
}

.edit-profile__form-group {
  margin-bottom: 5px;
}

.edit-profile__form-group--checkbox {
  padding-left: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}

.edit-profile__form-group--submit{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}

.edit-profile__form-input--submit{
  cursor: pointer;
}

.edit-profile__forgot-password{
  padding-top: 20px;
  display: block;
}

.edit-profile__error {
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}

.edit-profile__message{
  color: green;
  margin-bottom: 10px;
  display: inline-block;
}