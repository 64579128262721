.primary-sidebar__heading {
  display: inline-block;
  font-size: 24px;
  margin: 0 0 0 20px;
  line-height: .8;
}

.primary-sidebar__links {
  margin: 0 0 0 20px; 
}

.primary-sidebar__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.primary-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .primary-sidebar__heading {
      display: none;
  }
}