.scorecard {
  overflow-x: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.scorecard__table__cell-header, .scorecard__table__cell, .scorecard__nav-table__cell, .scorecard__segment-table__cell, .scorecard__notes-table__cell, .scorecard__notes-table__cell-header {
	padding: 5px;
	margin: 0px;
	border: 1px solid black;
}

.scorecard__table {
  width: 1154px;
	display: inline-block;
	border-collapse: collapse;
	background-color: #f2f5f7;
}

.scorecard__table__cell-header, .scorecard__notes-table__cell-header {
	font-size: 14px;
	padding: 5px;
	background-color: #77809B;
	color: #FFF494;
}

/*
* Segment Table
*/

.scorecard__segment-table {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
	overflow-y: scroll;
	height: 300px;
}

.scorecard__segment-table__cell--issue {
	border-top: 0;
	min-height: 45px;
}

.scorecard__segment-table__cell--source {
	border-bottom: 0;
}

.scorecard__segment-table__cell--target {
	border-bottom: 0;
}

.scorecard__segment-table__cell--beginning {
	color: #666666;
	text-align: center;
	font-style: italic;
	padding: 8px;
}

.scorecard__segment-table__cell--highlighter {
	background-color: #FFFACD;
	vertical-align: top;
	text-align: right;
	font-size: 13px;
}

.scorecard__segment-table__cell-selected {
  background: #fff185;
}

.scorecard__segment-table__row--segment, .scorecard__segment-table__row--issue, .scorecard__segment-table__row--segment-types{
	font-size: 13px;
}


.scorecard__segment-table__row--selected-top {
  border-top: 2px solid red;
}

.scorecard__segment-table__row--selected-bottom {
  border-bottom: 2px solid red;
}

.scorecard__segment-table__row--segment-types{
  font-size: 12px;
  background: #FFFFE5;
}

/*
* Nav Table
*/

.scorecard__nav-table {
	height: 300px;
	width: 24px;
	margin: 0;
	padding: 0;
	border: 0;
	border-collapse: collapse;
}

.scorecard__nav-table__row--button {
	height: 40px;
}

.scorecard__nav-table__cell {
	border: 0;
	padding: 0;
	margin: 0;
}

.scorecard__nav-table__row--highlight {
	height: 220px;
}

.scorecard__nav-table__cell--highlight {
	text-align: center;
}

.scorecard__nav-table__button {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-bottom: 5px;
  border: 1px;
  background-size: cover;
  cursor: pointer;
}

.scorecard__nav-table__button--down, .scorecard__nav-table__button--up {
	height: 40px;
	max-height: 40px;
	max-width: 24px !important;
	padding: 0;
	width: 24px !important;
	display: block;
  background-repeat: no-repeat;
	border-top: 1px solid black;
  border-bottom: 1px solid black;
}

/*
* Notes Table
*/

.scorecard__notes-table {
	border-collapse: collapse;
	position: relative;
	top: -3px;
	padding: 0;
	border: 0;
}

.scorecard__notes-table__textarea {
	border: 1px solid gray;
	width: 190px;
	height: 190px;
	margin: 3px;
	resize: none;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 15px;
}

.scorecard__notes-table__cell, .scorecard__notes-table__row {
	border: 0;
}

.scorecard__notes-table__cell--navigation {
	font-size: 12px;
}

/*
* Filter Table
*/

.scorecard__filter-table {
  float: right;
  width: 200px;
  border: 1px solid black;
  margin-top: 16px;
  background-color: #f2f5f7;
}

.scorecard__filter-table__heading {
  background-color: #77809B;
  border-bottom: 1px solid black;
  width: 190px;
  color: #FFF494;
  padding: 5px;
  font-weight: bold;
  font-size: 90%;
}

.scorecard__filter-table__advanced-button {
  margin-top: 20px;
}


