.confirmation-modal {
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
}

.confirmation-modal__content {
  border: 1px solid rgba(0,0,0,.2);
  background: white;
  border-radius: 4px;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
}

.confirmation-modal__buttons{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 150px;
}

.confirmation-modal__question {
  margin-bottom: 40px;
}

.confirmation-modal--hide {
  display: none;
}