.recover__heading{
  font-size: 30px;
  margin: 40px 0 20px 0;
}

.recover__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.recover__form-label{
  font-size: 14px;
}

.recover__form-input {
  margin-left: 5px;
}

.recover__form-group {
  margin-bottom: 5px;
}

.recover__form-group--submit{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
}

.recover__form-input--submit{
  cursor: pointer;
}

.recover__error {
  color: red;
  margin-bottom: 10px;
  display: inline-block;
}